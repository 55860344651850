<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <router-link to="/dashboard/landlordproperties">
            <img src="../assets/images/thickbackarrow.svg" alt="back" />
          </router-link>
          <h6>
            <router-link to="/dashboard/landlordproperties">
              Property List
            </router-link>
          </h6>
        </div>

        <div class="content">
          <div class="property_details">
            <h2>3 Bedroom Fully Detached Duplex</h2>
            <p>Maintama, Abuja</p>
            <p>N1,560,000</p>
            <small>Per Annum</small>
          </div>
          <div class="tenant_form">
            <div class="row">
              <div class="col-md-7 col-sm-12">
                <form>
                  <h4>WE WANT TO KNOW YOUR PROPERTY MANAGER</h4>
                  <div class="form-group">
                    <select
                      :class="{ errorData: dataa && who === '' }"
                      class="form-select llpm form-control input-bar"
                      aria-label="Landlord or property manager"
                      v-model="who"
                    >
                      <option value="" selected disabled
                        >Who are you adding?</option
                      >
                      <option value="agent">Agent</option>
                      <option value="lawyer">Lawyer</option>
                      <option value="property manager">Property Manager</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      :class="{ errorData: dataa && !firstname.trim() }"
                      class="form-control input-bar"
                      id="firstname"
                      placeholder="| Your Tenant's First Name"
                      v-model="firstname"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      :class="{ errorData: dataa && !lastname.trim() }"
                      class="form-control input-bar"
                      id="lastname"
                      placeholder="| Your Tenant's Last Name"
                      v-model="lastname"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="middlename"
                      placeholder="| Any other name"
                      v-model="middlename"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      :class="{ errorData: dataa && !email.trim() }"
                      class="form-control input-bar"
                      id="email"
                      placeholder="| Your Tenant's Email"
                      v-model="email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      :class="{ errorData: dataa && !phone.trim() }"
                      class="form-control input-bar"
                      id="phone"
                      placeholder="| Phone Number"
                      v-model="phone"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      :class="{ errorData: dataa && !current_location.trim() }"
                      class="form-control input-bar"
                      id="current_location"
                      placeholder="| Address"
                      v-model="current_location"
                    />
                  </div>
                  <div class="form-group">
                    <select
                      :class="{ errorData: dataa && countryid === '' }"
                      class="form-select form-control input-bar"
                      aria-label="Country"
                      v-model="countryid"
                      @change="getStates()"
                    >
                      <option value="" disabled>Country of Residence</option>
                      <option
                        v-for="(country, index) in countries"
                        :selected="countryid"
                        :key="index"
                        :value="country.id"
                        >{{ country.name }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <select
                      :class="{ errorData: dataa && stateid === '' }"
                      class="form-select form-control input-bar"
                      aria-label="State"
                      @change="getCities()"
                      v-model="stateid"
                    >
                      <option value="" selected disabled>State</option>
                      <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.id"
                        >{{ state.name }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <select
                      :class="{ errorData: dataa && cityid === '' }"
                      class="form-select form-control input-bar"
                      aria-label="Landlord or property manager"
                      v-model="cityid"
                    >
                      <option value="" selected disabled>City</option>
                      <option
                        v-for="(city, index) in cities"
                        :selected="cityid"
                        :key="index"
                        :value="city.id"
                        >{{ city.name }}</option
                      >
                    </select>
                  </div>
                </form>
              </div>
              <div class="col-md-5 col-sm-12">
                <div
                  class="rent_receipt"
                  :class="{ errorData: info && reciptimage === '' }"
                >
                  <div class="upload_receipt">
                    <p>Upload Tenancy Agreement</p>
                    <div
                      class="file_holder"
                      @click="$refs.payment.click()"
                      v-if="!recieptUpload"
                    >
                      <img
                        src="../assets/images/uploadfile.svg"
                        alt="receipt"
                      />
                      <input
                        ref="payment"
                        type="file"
                        name="receipt"
                        id="receipt"
                        class="d-none"
                        @change="uploadPaymentReceipt($event)"
                      />
                      <p v-if="!uplaodingData">
                        Click here to upload an image
                      </p>
                      <h6 class="uploading_text" v-if="uplaodingData">
                        Uploading please wait...
                      </h6>
                    </div>
                    <div v-else class="file-holder">
                      <div
                        class="file_holder file_uploaded_successfully"
                        @click="$refs.payment.click()"
                      >
                        <img
                          src="../assets/images/check-uploaded.svg"
                          alt="file"
                        />
                        <input
                          ref="payment"
                          type="file"
                          name="receipt"
                          id="receipt"
                          class="d-none"
                          @change="uploadPaymentReceipt($event)"
                        />
                        <p>
                          File uploaded successfully
                        </p>
                      </div>
                    </div>
                    <div class="file_format">
                      <h6>File formats PNG, JPEG or PDF</h6>
                      <p v-if="uplaodingData">{{ reciptimage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="save_btn">
            <button
              class="btn btn-block"
              @click="postData()"
              :disabled="loader"
            >
              SAVE
              <i
                class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                v-if="loader"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";

export default {
  name: "LandlordAddPM",
  components: {
    SmallNav
  },
  data() {
    return {
      activeTab: 1,
      loader: false,
      dataa: false,
      error: false,
      info: false,
      uplaodingData: false,
      recieptUpload: false,
      who: "",
      countries: [],
      states: [],
      cities: [],
      firstname: "",
      lastname: "",
      middlename: "",
      email: "",
      phone: "",
      propertyid: "",
      current_location: "",
      editDetailsLandlord: false,
      editDetailsAccount: false,
      countryid: "",
      stateid: "",
      cityid: "",
      detailsProps: "",
      reciptimage: "",
      disclaimer: false,
      propPin: ""
    };
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    // this.getCountries();
    this.detailsProps = Api.getPropertyDetails();
    console.log("PropId", JSON.stringify(this.detailsProps.id));
    // if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
    //   this.$router.push({
    //     path: `/dashboard/landlordproperties`,
    //   });
    // } else {
    //   this.getCountries();
    // }
    // if (this.detailsProps.haslandlord === "YES") {
    //   this.editDetailsLandlord = true;
    //   // this.getCountries();
    // } else {
    //   this.editDetailsLandlord = false;
    // }
    // if (this.detailsProps.hasaccountdetails === "YES") {
    //   this.editDetailsAccount = true;
    // } else {
    //   this.editDetailsAccount = false;
    // }
  },
  methods: {
    async getTenantDetails() {
      const propId = this.detailsProps.id;
      try {
        await Api.getRequest(`readaddedlandlorddetailsbypropertyid/${propId}`)
          .then(res => {
            if (this.detailsProps.haslandlord == "YES") {
              this.landlordPMDetails = res.data.landlorddetails;
              this.firstname = res.data.landlorddetails.firstname;
              this.lastname = res.data.landlorddetails.lastname;
              this.othernames = res.data.landlorddetails.othernames;
              this.email = res.data.landlorddetails.email;
              this.phone = res.data.landlorddetails.phone;
              this.current_location = res.data.landlorddetails.current_location;
              this.who = res.data.propertyObject.whomanages;
              if (this.countryid === "") {
                this.getCountries();
                this.countryid = res.data.landlorddetails.countryid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              if (this.stateid === "") {
                this.getStates();
                this.stateid = res.data.landlorddetails.stateid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              if (this.cityid === "") {
                this.getCities();
                this.cityid = res.data.landlorddetails.cityid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              this.countryid = res.data.landlorddetails.countryid;
              this.stateid = res.data.landlorddetails.stateid;
              // this.getStates();
              this.cityid = res.data.landlorddetails.cityid;
              // this.firstname = res.data.landlorddetails.firstname;
            }
            if (this.detailsProps.hasaccountdetails == "YES") {
              this.landlordAccountDetails = res.data.otherdetails;
              this.bankname = res.data.otherdetails.bankname;
              this.accountnumber = res.data.otherdetails.accountnumber;
              this.accountname = res.data.otherdetails.accountname;
            }

            this.landlordPMDetails = res.data.landlorddetails;
            // this.landlordAccountDetails = res.data.otherdetails;
            console.log("fetch Landlord details by pin", res);
          })
          .catch(err => {
            console.log("error", err);
            // this.uplaodingData = false;
            // this.recieptUpload = false;
          });
      } finally {
        console.log("Closed");
      }
    },
    async uploadPaymentReceipt() {
      this.uplaodingData = true;
      this.$store.commit("setApiWarning", "Uploading...");
      this.file = this.$refs.payment.files[0];
      this.reciptimage = this.file.name;
      if (!this.file) {
        this.uplaodingData = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.uplaodingData = true;
      } else {
        this.uplaodingData = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a pdf or image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.uplaodingData = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be more than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadpreviouspayment`, formData)
            .then(res => {
              this.$store.commit("setApiWarning", "");
              this.reciptimage === "";
              this.uplaodingData = false;
              if (res.data.error) {
                // this.fileSizeType = true;
                return this.$store.commit("setApiFailed", res.data.error);
              } else if (res.data.success) {
                this.recieptUpload = true;
                this.reciptimage = res.data.filename;
                this.propimgname = res.data.filename;
                this.$store.commit("setApiSuccess", "success");
              }
            })
            .catch(err => {
              this.uplaodingData = false;
              this.recieptUpload = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    postData() {
      if (this.detailsProps.verified == "YES") {
        this.propPin = this.detailsProps.pin;
        $("#cantaddedit")
          .addClass("fade")
          .modal("show");
      } else {
        if (this.activeTab === 1 && this.editDetailsLandlord === false) {
          this.addedLandlord();
        }
        if (this.activeTab === 2 && this.editDetailsLandlord === false) {
          this.addAccountDetails();
        }
        if (this.activeTab === 1 && this.editDetailsAccount === true) {
          // console.log("Edit Landlord details");
          console.log("Active tab", this.activeTab);
          this.editLandlord();
        }
        if (this.activeTab === 2 && this.editDetailsAccount === true) {
          this.addAccountDetails();
        }
      }
    },
    async editTenant() {
      this.loader = true;
      this.dataa = true;
      console.log("clicked");
      if (
        this.email === "" ||
        this.firstname === "" ||
        this.current_location === "" ||
        this.lastname === "" ||
        this.who === "" ||
        this.phone === ""
      ) {
        this.dataa = true;
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill required fields");
      } else {
        // this.dataa = false;
        // console.log("testing validation");
        try {
          // console.log("Check");
          await Api.postRequest(`landlordedittenant`, {
            whomanages: this.who,
            firstname: this.firstname,
            lastname: this.lastname,
            middlename: this.middlename,
            email: this.email,
            phone: this.phone,
            propertyid: this.detailsProps.id,
            current_location: this.current_location,
            countryid: this.countryid,
            stateid: this.stateid,
            cityid: this.cityid
          })
            .then(res => {
              console.log("edidted landlord", res);
              this.loader = false;
              if (res.data.success) {
                this.email === "";
                this.firstname === "";
                this.current_location === "";
                this.lastname === "";
                this.who === "";
                this.phone === "";
                this.countryid === "";
                this.stateid === "";
                this.cityid === "";
                return this.$store.commit("setApiSuccess", res.data.success);
              } else {
                this.loader = false;
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log(err);
              this.loader = false;
              return this.$store.commit("setApiFailed", err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async landlordAddTenant() {
      this.loader = true;
      this.dataa = true;
      console.log("clicked");
      if (
        this.email === "" ||
        this.firstname === "" ||
        this.current_location === "" ||
        this.lastname === "" ||
        this.who === "" ||
        this.phone === ""
      ) {
        this.dataa = true;
        this.loader = false;
        // this.error = true;
        return this.$store.commit("setApiFailed", "Fill required fields");
      } else {
        // this.dataa = false;
        // console.log("testing validation");
        try {
          // console.log("Check");
          await Api.postRequest(`landlordaddtenant`, {
            whomanages: this.who,
            firstname: this.firstname,
            lastname: this.lastname,
            middlename: this.middlename,
            email: this.email,
            phone: this.phone,
            propertyid: this.detailsProps.id,
            current_location: this.current_location,
            countryid: this.countryid,
            stateid: this.stateid,
            cityid: this.cityid
          })
            .then(res => {
              console.log("added landlord", res);
              this.loader = false;
              if (res.data.success) {
                this.email === "";
                this.firstname === "";
                this.current_location === "";
                this.lastname === "";
                this.who === "";
                this.phone === "";
                this.countryid === "";
                this.stateid === "";
                this.cityid === "";
                this.loader = false;
                if (this.detailsProps.hasaccountdetails == "NO") {
                  this.activeTab = 2;
                }
                return this.$store.commit("setApiSuccess", res.data.success);
              } else {
                this.loader = false;
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log(err);
              this.loader = false;
              return this.$store.commit("setApiFailed", err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async getCountries() {
      this.$store.commit("setApiWarning", "Loading countries...");
      try {
        await Api.getRequest(`fetchallcountries`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading");
            if (res.data.success) {
              this.countries = res.data.countries;
              console.log("Countries", res);
              // this.getStates();
              return this.$store.commit("setApiSuccess", "countries loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getStates: async function() {
      const id = this.countryid;
      this.$store.commit("setApiWarning", "Loading state...");
      try {
        await Api.getRequest(`fetchstatesbycountryid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.states = res.data.states;
              return this.$store.commit("setApiSuccess", "state loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: async function() {
      const id = this.stateid;
      this.$store.commit("setApiWarning", "Loading cities");
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.cities = res.data.cities;
              return this.$store.commit("setApiSuccess", "cities loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}

a {
  color: inherit !important;
  text-decoration: none;
}
.properties_count {
  text-align: left;
  padding: 30px 20px;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
  }
}

.content {
  max-width: 992px;
  width: 100%;
  margin: auto;
  padding: 0px 0 20px;
  background: $secondary;
  border-radius: 10px 10px 0px 0px;
  .property_details {
    background: #0033ea;
    border: 0.2px solid #0033ea;
    border-radius: 10px 10px 0px 0px;
    padding: 30px 30px;
    text-align: left;
    h2 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: $secondary;
    }
    p {
      font-family: Gotham;
      font-style: normal;
    }
    p:nth-child(2) {
      font-size: 14px;
      line-height: 13px;
      font-weight: 400;
      color: $secondary;
      margin-bottom: 10px;
    }
    p:nth-child(3) {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $secondary;
      margin-bottom: 0px;
    }
    small {
      font-family: Gotham;
      font-style: italic;
      font-size: 11px;
      line-height: 15px;
      color: $secondary;
      padding-left: 10px;
    }
  }

  .tenant_form {
    margin-top: 30px;
    form {
      border: 0.2px solid #f3f1f1;
      margin: 0 20px;
      padding: 14px 50px;
      h4 {
        font-family: Gotham-medium;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}

.form-group {
  margin-bottom: 1.9rem;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $black;
  }
  .form-check-label {
    display: inline-block;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 153%;
    text-align: left;
  }
}

.required_flelds {
  p {
    color: #dc3545;
    font-family: Gotham;
    font-style: normal;
    font-size: 20px;
  }
}

.errorData {
  border: 0.5px solid #dc3545 !important;
}
.successData {
  border: 0.5px solid #8596d3 !important;
}

.llpm {
  background: $primary !important;
  color: $secondary !important;
}
.input-bar {
  border-radius: 0;
  background: $secondary;
  height: 44px;
  border: 0.5px solid #8596d3;
  // border: 0.2px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  height: 50px;
  align-content: normal;
  font-family: Gotham;
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  // text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
  option {
    background: $secondary;
    color: $black;
  }
}

.upload_receipt {
  margin: 20px 33px;
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    text-align: left;
    color: $black;
    margin-bottom: 20px;
  }
}
.file_holder {
  padding: 36px 20px;
  background: $secondary;
  box-shadow: 10px 10px 30px rgb(0 0 0 / 10%);
  cursor: pointer;
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.9);
  }
  h6:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    color: #ff9c2a;
  }

  .file_uploaded_successfully {
    padding-top: 3.5rem !important;
    p:nth-child(3) {
      color: #28a745 !important;
    }
  }
  // .upload-file-holder {
  //   margin: 3rem auto;
  //   margin-bottom: 1.5rem;
  //   width: 285px;
  //   height: 202px;
  //   background: #ffffff;
  //   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  // }

  .uploading_text {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
.rent_receipt {
  padding-top: 7rem;
  //   border: 1px solid #e5e5e5;
  border: 0.5px solid #f3f1f1;
  border-radius: 5px;
  height: 100%;
  margin: 0 10px;
  //   margin-top: 25px;
}

.file_format {
  padding-top: 20px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #b93623;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    // display: flex;
    align-items: center;
    color: #2fc643;
  }
}

.save_btn {
  margin: 30px 32px 0;
  button {
    background: $primary;
    color: $secondary;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    height: 43px;
  }
}

@media screen and (max-width: 599px) {
  .rent_receipt {
    padding-top: 0;
  }
  .content {
    .tenant_form {
      form {
        padding: 14px 0;
      }
    }
  }
}
</style>
